<template>
    <div class="pcContentFive">
        <img src="http://iv.vu818.com/img/bg1 (1).jpg" alt="" class="pcBg" />
        <div class="pcNum">04</div>
        <div class="pcfBox">
            <!-- 左边文字小图片 -->
            <div class="pcFLeftText">
                <!-- 文字 -->
                <div class="pcFTop">
                    <div class="pcFIcon">
                        <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                    </div>
                    <div class="pcFBig">VR交互体验设计，增加产品的模拟体验</div>
                    <div class="pcFSmall">
                        对于需要展现产品立体面、或者空间感的产品，过去需要花很大代价才能实现虚拟现实效果。当今VR交互体验设计很成熟，能满足大多数需要，并且可以非常经济地完成VR产品展示、VR空间展示。
                    </div>
                </div>
                <!-- 小图片 -->
                <div class="pcFBottom">
                    <ul>
                        <li class="pcFLiImg" @mousemove="showRight(1)">
                            <img class="pcFImg" src="http://iv.vu818.com/img/tpc41.jpg" alt="" />
                        </li>
                        <li class="pcFLiImg" @mousemove="showRight(2)">
                            <img class="pcFImg" src="http://iv.vu818.com/img/tpc42.jpg" alt="" />
                        </li>
                        <li class="pcFLiImg" @mousemove="showRight(3)">
                            <img class="pcFImg" src="http://iv.vu818.com/img/tpc43.jpg" alt="" />
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 右边大图片 -->
            <div class="pcFRighttImg">
                <div class="pcFBigImg">
                    <img src="http://iv.vu818.com/img/pcdn.png" alt="" />
                </div>
                <ul>
                    <li class="pcRLiImg1" v-show="showRImg === 1">
                        <img class="pcRImg" src="http://iv.vu818.com/img/tpc41.jpg" alt="" />
                    </li>
                    <li class="pcRLiImg2" v-show="showRImg === 2">
                        <img class="pcRImg" src="http://iv.vu818.com/img/tpc42.jpg" alt="" />
                    </li>
                    <li class="pcRLiImg3" v-show="showRImg === 3">
                        <img class="pcRImg" src="http://iv.vu818.com/img/tpc43.jpg" alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PcContentFive',
    data() {
        return {
            showRImg: 1,
        };
    },
    methods: {
        showRight(i) {
            this.showRImg = i;
        },
    },
};
</script>

<style lang="less" scoped>
.pcContentFive {
    width: 100%;
    height: 100%;

    margin-top: 5px;
    position: relative;
    overflow: hidden;
    .pcBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        object-fit: cover;
    }
    .pcNum {
        position: absolute;
        right: -22px;
        top: -90px;
        font-family: 'DIN-Medium';
        font-size: 400px;
        color: #ff6c00;
        opacity: 0.05;
    }
    .pcfBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
    }
    // 左边文字小图片
    .pcFLeftText {
        width: 555px;
        height: 100%;
        float: left;
        margin-left: 50px;
        text-align: left;
        // 文字
        .pcFTop {
            width: 100%;
            height: 300px;
            margin-top: 100px;
            .pcFIcon {
                width: 70px;
                height: 75px;
                overflow: hidden;
                position: relative;
                img {
                    position: absolute;
                    top: -135px;
                    left: -115px;
                }
            }
            .pcFBig {
                width: 100%;
                height: 95px;
                line-height: 50px;
                font-size: 36px;
            }
            .pcFSmall {
                width: 100%;
                height: 100px;
                margin-top: 20px;
                line-height: 30px;
                color: gray;
            }
        }
        // 图片
        .pcFBottom {
            width: 100%;
            height: 68px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .pcFLiImg {
                    width: 22%;
                    height: 100%;
                    transition: 0.5s;
                    cursor: pointer;
                    border-radius: 20px;
                    overflow: hidden;
                    .pcFImg {
                        width: 100%;
                        height: 100%;
                    }
                }
                .pcFLiImg:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                    box-sizing: border-box;
                }
            }
        }
    }
    // 右边大图片
    .pcFRighttImg {
        width: 606px;
        height: 500px;
        margin-top: 100px;
        float: left;
        position: relative;
        overflow: hidden;
        margin-left: 50px;
        .pcFBigImg {
            width: 606px;
            height: 500px;
        }
        ul {
            width: 100%;
            .pcRLiImg1,
            .pcRLiImg2,
            .pcRLiImg3,
            .pcRLiImg4 {
                position: absolute;
                top: 13px;
                left: 12px;
                .pcRImg {
                    width: 582px;
                    height: 357px;
                    border-radius: 5px;
                }
            }
        }
    }
}
</style>
